<template>
  <AView
    :value="value"
    :actions="actions"
    :active="question.id === activeQuestionId"
    :name="question.name"
    ref="question-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @action="(action) => action.handler({ from: value, question })"
  >
    <template v-slot:content="{}">
      <QuestionDetailsVue
        :value="question"
        :permissions="value.permissions"
        @action="(action) => action.handler({ from: value, question })"
      ></QuestionDetailsVue>
    </template>
  </AView>
</template>
  
  
  <script>
import { mapGetters, mapState } from "vuex";
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";
import QuestionDetailsVue from "../../../../../../components/wad/organisms/knowledgeBase/questions/QuestionDetails.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { QUESTION_BUTTONS } from "../../constants/actions.gh";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    QuestionDetailsVue,
  },
  data() {
    return {
      question: {},
    };
  },
  computed: {
    ...mapState("QuestionStore", ["activeQuestionId", "displayedQuestions"]),
    ...mapGetters("QuestionStore", ["questionById"]),
    actions: function () {
      return PermissionsHelper.getActions(
        [
          QUESTION_BUTTONS.GENERAL_EDITOR_MAIN,
          QUESTION_BUTTONS.SETTINGS_EDITOR_MAIN,
        ],
        this.value.permissions
      );
    },
  },
  created() {
    this.$store.dispatch("QuestionStore/GetQuestion", {
      id: this.value.relation.params.questionId,
    });

    this.question = this.questionById(this.value.relation.params.questionId);
  },
  methods: {
    onHover() {
      this.$store.commit(
        "QuestionStore/setActiveId",
        this.value.relation.params.questionId
      );
    },
  },
  watch: {
    displayedQuestions: {
      async handler(newVal) {

        if (newVal) {
          this.question = this.questionById(
            this.value.relation.params.questionId
          );
        }
      },
      deep: true,
    },
  },
};
</script>