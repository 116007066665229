<template>
  <v-card class="transparent mt-4 pa-2 elevation-0">
    <v-card-title>
      {{ String(value.name).toUpperCase() }}
    </v-card-title>
    <v-card-text>
      <v-slide-group
        v-model="tagIndex"
        class="my-4"
        center-active
        show-arrows
        v-if="value.tags && value.tags.length"
      >
        <v-slide-item
          v-for="item in value.tags"
          :key="item.tag.id"
          v-slot="{ toggle }"
        >
          <v-chip
            class="ma-2"
            :color="item.tag.color"
            @click="toggle"
            label
            text-color="white"
          >
            <v-icon v-if="item.tag.icon" left> {{ item.tag.icon }} </v-icon>
            {{ item.tag.name }}
          </v-chip>
        </v-slide-item>
      </v-slide-group>

      <h3 class="my-2">DESCRIPTION :</h3>
      <p class="simple-text" v-html="value.description"></p>

      <h3 class="my-2">GOAL:</h3>
      <p class="simple-text" v-html="value.goal"></p>

      <!-- Useful Links -->
      <FormSection
        underline
        right
        class="mt-0"
        :label="'Useful Links'"
        :icon="'mdi-link-variant'"
        :actions="linkActions"
        :editable="!!linkActions.length"
        @action="(action) => $emit('action', action)"
      ></FormSection>

      <LinksListVue
        v-if="value.links && value.links.length > 0"
        :value="value.links"
      ></LinksListVue>
    </v-card-text>
  </v-card>
</template>
    
    
<script>
import { QUESTION_BUTTONS } from "../../../../../views/src/apps/knowledge-base/constants/actions.gh";
import { PermissionsHelper } from "../../../../helpers/permissions.helper";
import FormSection from "../../../atoms/common/FormSection.vue";
import LinksListVue from "../links/LinksList.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FormSection,
    LinksListVue,
  },
  data: () => ({
    tagIndex: null,
  }),
  computed: {
    linkActions: function () {
      return PermissionsHelper.getActions(
        QUESTION_BUTTONS.GENERAL_EDITOR,
        this.permissions
      );
    },
  },
  async created() {},
  methods: {},
  watch: {},
};
</script>